import type { DeviceType } from '~/utils/cache'

export const BREAKPOINTS = {
  accountMenuMobile: '375px', // move account icon to burger menu if smaller - adapted from FIM page
  xs: '400px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  pdp: '1096px', // on CL pdp product details is split into columns
  header: '1140px', // hide the individual header nav points
  glassesPDPxl: '1200px', // the breakpoint where the right side bar jumps below the image - adapted from FIM page
  xl: '1280px',
  '2xl': '1440px',
  rxLensOptions: '1560px',
  '3xl': '1680px',
  '4xl': '1920px',
}

export const DEVICE_TYPE_BREAKPOINTS: Record<DeviceType, string> = {
  mobile: BREAKPOINTS.accountMenuMobile,
  tablet: BREAKPOINTS.sm,
  desktop: BREAKPOINTS.header,
}

export function isValidBreakpoint(
  key: string,
): key is keyof typeof BREAKPOINTS {
  return Object.keys(BREAKPOINTS).includes(key)
}
