<template>
  <span
    class="wrapper m-0 box-border overflow-hidden border-0 bg-none p-0 opacity-100"
    :class="{
      [layout]: true,
      'absolute inset-0 block size-full': layout === 'fill',
      'relative block': layout === 'responsive',
      'relative inline-block max-w-full': layout === 'intrinsic',
    }"
  >
    <span
      v-if="hasSizer"
      class="sizer m-0 box-border block size-[initial] border-0 bg-none p-0 opacity-100"
      :style="sizerStyle"
    >
      <img
        v-if="sizerSvgUrl"
        class="sizer-svg m-0 block size-[initial] max-w-full border-0 bg-none p-0 opacity-100"
        alt=""
        :aria-hidden="true"
        :src="sizerSvgUrl"
        :loading="preload ? 'eager' : loading"
      />
    </span>
    <img
      v-if="isSvg"
      :src="imageSrc"
      :alt="alt"
      :decoding="decoding"
      :loading="preload ? 'eager' : loading"
      :fetchpriority="preload ? 'high' : 'auto'"
      :width="width || undefined"
      :height="height || undefined"
      :class="{
        'size-full object-contain': objectFit === 'contain',
        'absolute inset-0 m-auto box-border block size-0 max-h-full min-h-full min-w-full max-w-full border-none p-0':
          hasSizer,
      }"
    />
    <picture v-else>
      <source
        v-if="mobileSources && mobileSources.length"
        :sizes="imageSizes"
        :media="mqMobile"
        :srcset="
          mobileSources.map((file) => `${file.src} ${file.width}w`).join(',')
        "
      />
      <source
        v-if="desktopSources && desktopSources.length"
        :sizes="imageSizes"
        :media="mqDesktop"
        :srcset="
          desktopSources.map((file) => `${file.src} ${file.width}w`).join(',')
        "
      />
      <img
        v-if="imageSrc"
        ref="image"
        :src="imageSrc"
        :alt="alt"
        :width="width || undefined"
        :height="height || undefined"
        :decoding="decoding"
        :loading="preload ? 'eager' : loading"
        :fetchpriority="preload ? 'high' : 'auto'"
        :style="imageStyle"
        :class="{
          'absolute inset-0 m-auto box-border block size-0 max-h-full min-h-full min-w-full max-w-full border-none p-0':
            layout !== 'raw',

          'size-full object-contain': objectFit === 'contain',
          'size-full object-cover': objectFit === 'cover',
          'size-full object-fill': objectFit === 'fill',
          'h-auto w-full': objectFit === undefined,
          'object-scale-down': !objectFit || layout === 'intrinsic',
          'size-full object-scale-down': objectFit === 'scale-down',
          'object-center': objectPosition === 'center',
          'object-top': objectPosition === 'top',
          'object-top-right': objectPosition === 'top-right',
          'object-right': objectPosition === 'right',
          'object-bottom-right': objectPosition === 'bottom-right',
          'object-bottom': objectPosition === 'bottom',
          'object-bottom-left': objectPosition === 'bottom-left',
          'object-left': objectPosition === 'left',
          'object-top-left': objectPosition === 'top-left',
        }"
        @load="onImgLoad"
      />
    </picture>
  </span>
</template>

<script setup lang="ts">
/* eslint sonarjs/cognitive-complexity: 1 */
import type { ImageModifiers } from '@nuxt/image'
import type { Link } from '@unhead/vue'
import { objectHash } from 'ohash'
import type { PictureLayout, ObjectFit, ObjectPosition } from '~/constants/ui'
import { BREAKPOINTS, isValidBreakpoint } from '~/constants/tailwind'

type Source = {
  width: number
  height?: number
  src: string
}

type FocusPoint = {
  x: number
  y: number
}

const parseStoryBlokUrl = (url: string) => {
  const [, curWidth, curHeight] = /\/(\d+)x(\d+)\//.exec(url) || []
  const [, originalWidth, originalHeight] = /.*\/(\d+)x(\d+)\//.exec(url) || []
  const origRatio = parseInt(originalWidth, 10) / parseInt(originalHeight, 10)

  let width = parseInt(curWidth, 10)
  let height = parseInt(curHeight, 10)

  if (width && !height && origRatio) {
    height = width / origRatio
  } else if (!width && height && origRatio) {
    width = height * origRatio
  }

  return {
    width,
    height,
    originalWidth: parseInt(originalWidth, 10),
    originalHeight: parseInt(originalHeight, 10),
  }
}

type Props = {
  src?: string
  mobile?: string // ''
  desktop?: string // ''
  strict?: boolean // false
  provider: string
  sizes?: string
  width?: number | string
  height?: number | string
  ratioMobile?: number | string
  ratioDesktop?: number | string
  modifiers?: Partial<ImageModifiers>
  alt?: string
  loading?: 'lazy' | 'eager'
  decoding?: 'sync' | 'async' | 'auto'
  preload?: boolean // false
  layout?: PictureLayout // 'intrinsic'
  objectFit?: ObjectFit // ''
  objectPosition?: ObjectPosition // ''
  focusMobile?: FocusPoint
  focusDesktop?: FocusPoint
  mediaQueryMobile?: string // '(max-width: 639px) and (orientation: portrait)'
  mediaQueryDesktop?: string // '(min-width: 640px), (orientation: landscape)',
  widths?: Partial<Record<keyof typeof BREAKPOINTS, number>>
  maxWidth?: number // 1400
}

const props = withDefaults(defineProps<Props>(), {
  src: undefined,
  mobile: '',
  desktop: '',
  strict: false,
  alt: '',
  loading: 'lazy',
  decoding: 'async',
  preload: false,
  layout: 'intrinsic',
  mediaQueryMobile: '(max-width: 639px) and (orientation: portrait)',
  mediaQueryDesktop: '(min-width: 640px), (orientation: landscape)',
  maxWidth: 1400,
  widths: undefined,
  sizes: undefined,
  width: undefined,
  height: undefined,
  ratioMobile: undefined,
  ratioDesktop: undefined,
  modifiers: undefined,
  objectFit: undefined,
  objectPosition: undefined,
  focusMobile: undefined,
  focusDesktop: undefined,
})

const mobileSrc: string =
  typeof props.src !== 'undefined' ? props.src : props.mobile
const desktopSrc: string =
  typeof props.src !== 'undefined' ? props.src : props.desktop

const $img = useImage()
const md = useViewport().isGreaterOrEquals('md')
const imageWidths = [
  ...new Set([
    // Add configured widths
    ...Object.values($img?.options?.screens || {}),
    // Add widths props + double sized widths for high density screens
    ...Object.values(props?.widths || {}).flatMap((w) => [w, w * 2]),
  ]),
].sort((a, b) => a - b)
const image = ref<HTMLImageElement>()

const desktopRatio =
  typeof props.ratioDesktop === 'string'
    ? parseFloat(props.ratioDesktop)
    : props.ratioDesktop

const mobileRatio =
  typeof props.ratioMobile === 'string'
    ? parseFloat(props.ratioMobile)
    : props.ratioMobile

const imageSrc = computed(() => {
  if (!md && mobileSrc) {
    return mobileSrc
  } else if (desktopSrc) {
    return desktopSrc
  }
  return mobileSrc !== '' && mobileSrc !== undefined ? mobileSrc : desktopSrc
})

const width = ref(Number(props.width) || 0)
const height = ref(Number(props.height) || 0)
const maxWidth = ref(props.maxWidth)
const maxHeight = ref(Infinity)

if (props.provider === 'storyblok') {
  // get with & height from url
  const dimensions = parseStoryBlokUrl(imageSrc.value ?? '')
  if (!isNaN(dimensions.width)) {
    width.value = dimensions.width
  }

  if (!md && mobileRatio && !isNaN(dimensions.width)) {
    height.value = Math.round(dimensions.width / mobileRatio)
  } else if (desktopRatio && !isNaN(dimensions.width)) {
    height.value = Math.round(dimensions.width / desktopRatio)
  } else if (!isNaN(dimensions.height)) {
    height.value = dimensions.height
  }

  if (!isNaN(dimensions.originalWidth)) {
    maxWidth.value = dimensions.originalWidth
  }

  if (!isNaN(dimensions.originalHeight)) {
    maxHeight.value = dimensions.originalHeight
  }
}

const hasSizer = computed(
  () =>
    !!sizerSvgUrl.value && ['intrinsic', 'responsive'].includes(props.layout),
)
const sizerSvgUrl = computed(
  () =>
    props.layout === 'intrinsic' &&
    width.value &&
    height.value &&
    `data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27${width.value}%27%20height=%27${height.value}%27/%3e`,
)

const sizerStyle = computed(() => {
  switch (props.layout) {
    case 'intrinsic':
      return { maxWidth: '100%' }
    case 'responsive':
      return { paddingTop: paddingTop.value }
    default:
      return {}
  }
})

// For SVG no need to load different size variants
const isSvg = computed(() => imageSrc.value?.includes('.svg') ?? false)

const getSources = (
  src: string,
  query = {},
  ratio: number | undefined,
  focus: FocusPoint | undefined,
): Source[] => {
  if (!src || isSvg.value) {
    return []
  }

  const modifiers: Partial<ImageModifiers> = { ...props.modifiers }
  const { originalWidth = Infinity, originalHeight = Infinity } =
    props.provider === 'storyblok' ? parseStoryBlokUrl(src) : {}

  if (
    focus &&
    props.provider === 'storyblok' &&
    originalWidth !== Infinity &&
    originalHeight !== Infinity
  ) {
    const x = Math.min(originalWidth - 1, Math.round(originalWidth * focus.x))
    const y = Math.min(originalHeight - 1, Math.round(originalHeight * focus.y))
    const focal = `${x}x${y}:${x + 1}x${y + 1}`
    modifiers.filters = { ...(modifiers.filters || {}), focal }
  }

  const sortedImageWidths = (
    originalWidth !== Infinity ? [...imageWidths, originalWidth] : imageWidths
  ).sort((a, b) => a - b)

  return sortedImageWidths
    .filter((width) => width <= originalWidth)
    .map((width) => {
      const height = ratio ? Math.round(width / ratio) : 0
      const result: Source = {
        width,
        height,
        src: $img(
          src,
          { ...query, width, height, ...modifiers },
          { provider: props.provider },
        ),
      }

      // Required for new Storyblok image Service
      // https://www.storyblok.com/docs/image-service#examples
      const matches = result.src.match(/(.*\.com)(.*)(\/f\/.*)/)
      if (matches && matches.length) {
        result.src = matches[2]
          ? `${matches[1]}${matches[3]}/m${matches[2]}`
          : `${matches[1]}${matches[3]}`
      }

      return result
    })
}

// compute sizes attribute
const imageSizes = computed(() => {
  if (props.sizes) {
    return props.sizes
  }

  // compute sizes for passed widths attribute
  if (props.widths) {
    return [
      ...Object.entries(props.widths || {})
        .map(([breakpoint, width]) => [
          breakpoint,
          Math.min(width, props.maxWidth, maxWidth.value),
        ])
        .filter(([breakpoint]) => isValidBreakpoint(breakpoint.toString()))
        .sort(
          ([a], [b]) =>
            parseInt(BREAKPOINTS[b as keyof typeof BREAKPOINTS], 10) -
            parseInt(BREAKPOINTS[a as keyof typeof BREAKPOINTS], 10),
        )
        .filter(([, width], index, entries) => {
          return !entries[index + 1] || entries[index + 1][1] !== width
        })
        .flatMap(([breakpoint, width]) => {
          const key = '' + breakpoint
          if (isValidBreakpoint(key)) {
            return [`(min-width: ${BREAKPOINTS[key]}) ${width}px`]
          }

          return []
        }),
      '100vw',
    ].join(', ')
  }

  // use default value preventing overscale
  const computedMaxWidth = Math.min(
    ...[props.maxWidth, width.value, 1920, maxWidth.value].filter(Boolean),
  )
  return `(min-width: ${computedMaxWidth}px) ${computedMaxWidth}px, 100vw`
})

const mobileSources = getSources(
  mobileSrc || desktopSrc,
  {},
  mobileRatio,
  props.focusMobile || props.focusDesktop,
)

const desktopSources =
  (mobileSrc && desktopSrc && mobileSrc !== desktopSrc) ||
  mobileRatio !== desktopRatio ||
  props.focusMobile !== props.focusDesktop
    ? getSources(
        desktopSrc || mobileSrc,
        {},
        desktopRatio,
        props.focusDesktop || props.focusMobile,
      )
    : undefined

const paddingTop = computed(() => {
  const quotient = height.value / width.value

  return isNaN(quotient) ? '100%' : `${Math.round(quotient * 10000) / 100}%`
})

const loadedImageSrc = ref('')
const onImgLoad = () => {
  // prevent triggering load event multiple times
  if (loadedImageSrc.value === image.value?.currentSrc) {
    return
  }

  loadedImageSrc.value = image.value?.currentSrc ?? ''
  width.value = Math.min(maxWidth.value, image?.value?.naturalWidth ?? 0)
  height.value = Math.min(maxHeight.value, image?.value?.naturalHeight ?? 0)
}

const mqMobile = computed(() =>
  props.strict ||
  (Boolean(mobileSources?.length) && Boolean(desktopSources?.length))
    ? props.mediaQueryMobile
    : undefined,
)

const mqDesktop = computed(() =>
  props.strict ||
  (Boolean(desktopSources?.length) && Boolean(mobileSources?.length))
    ? props.mediaQueryDesktop
    : undefined,
)

// Overwrite object position with focus point if available
const mobileObjectPosition = computed(() =>
  props.focusMobile
    ? `${props.focusMobile.x * 100}% ${props.focusMobile.y * 100}%`
    : undefined,
)

const desktopObjectPosition = computed(() =>
  props.focusDesktop
    ? `${props.focusDesktop.x * 100}% ${props.focusDesktop.y * 100}%`
    : undefined,
)

const imageStyle = computed(() => {
  if (mobileObjectPosition.value && desktopObjectPosition.value) {
    return {
      objectPosition: md
        ? desktopObjectPosition.value
        : mobileObjectPosition.value,
    }
  }

  return {
    objectPosition: mobileObjectPosition.value || desktopObjectPosition.value,
  }
})

// HEAD
// eslint-disable-next-line sonarjs/cognitive-complexity
useHead(() => {
  const { preload, sizes } = props

  const links: Link[] = []

  if (preload) {
    const createLink = (sources: Source[]): Link => {
      const result: Link = {
        rel: 'preload',
        as: 'image',
        fetchpriority: 'high',
        key: objectHash(sources),
        imagesrcset: sources
          .map((file) => `${file.src} ${file.width}w`)
          .join(','),
      }

      if (sizes) {
        result.imagesizes = sizes
      }

      return result
    }
    const linkMobile =
      mobileSources && mobileSources.length
        ? createLink(mobileSources as Source[])
        : undefined

    const linkDesktop =
      desktopSources && desktopSources.length
        ? createLink(desktopSources as Source[])
        : undefined

    if (linkMobile && mqMobile.value) {
      // @ts-expect-error media is not available in {}
      linkMobile.media = mqMobile.value
    }

    if (linkDesktop && mqDesktop.value) {
      // @ts-expect-error media media is not available in {}
      linkDesktop.media = mqDesktop.value
    }
    // TODO RN both images are added as to the preload links
    // we should combine both imagesrcsets into one with correct sizes
    if (linkMobile) {
      links.push(linkMobile)
    }

    if (linkDesktop) {
      links.push(linkDesktop)
    }
  }
  return { link: links }
})
</script>
